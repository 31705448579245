  .rbc-time-header {
    z-index: 1;
  }

  .rbc-label {
    color: rgb(117, 117, 117);
  }

  .rbc-day-slot .rbc-event {
    padding: 2px 10px 10px !important;
}

.rbc-event-label {
    font-size: 14px;
    margin-bottom: 10px;
}

.rbc-event-content {
    font-size: 12px;
}

.rbc-events-container {
  overflow-x: hidden;
}

.rbc-day-slot.rbc-time-column.rbc-now.rbc-today {
  background-color: #fff;
}
.rbc-today{
  background-color: #fff;
}
.rbc-time-gutter {
  background-color: #fff;
}